import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/HomePage.css';

const HomePage = () => {
  return (
    <div className="home-page">
      <div className="home-content">
        <h1>Welcome! I am AI Loan Officer! We are glad to have you here.</h1>
        <p> We are here to help you with your loan application. Explore our services and click the below button to start chatting</p>
        <Link to="/chatbot" className="chatbot-button">
          Start Chatting
        </Link>
      </div>
    </div>
  );
};

export default HomePage;