import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ChatMessage from './ChatMessage';
import ChatOptions from './ChatOptions';
import '../styles/Chatbot.css';
import CircularProgress from '@mui/material/CircularProgress';

const Chatbot = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isListening, setIsListening] = useState(false);
    const [expandedCards, setExpandedCards] = useState({});
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef(null);
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = SpeechRecognition ? new SpeechRecognition() : null;

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleSendMessage = async (query, isHidden = false, isAgentMessage = false) => {
        if (!query.trim()) return;

        if (isAgentMessage) {
            setMessages((prev) => [...prev, { sender: 'agent', text: query }]);
            return;
        }

        if (isHidden) {
            const headers = {};
            headers['Content-Type'] = 'application/json';
            axios.defaults.withCredentials = true;
            try {
                const response = await axios.post('https://api.loan.ask-myna.com/ask-myna', {
                    query: query,
                    languageCode: 'en-US'
                });                
                let agentResponse = response.data.response || 'Sorry, I did not understand that.';
                try {
                    agentResponse = JSON.parse(agentResponse);
                } catch {
                    // to handle
                }

                setMessages((prev) => [...prev, { sender: 'agent', text: agentResponse }]);
            } catch (error) {
                setMessages((prev) => [...prev, { sender: 'agent', text: 'Error fetching response for hidden message.' }]);
            }
            return;
        }

        setMessages((prev) => [...prev, { sender: 'user', text: query }]);
        setInput('');
        setIsLoading(true);
        const headers = {};
        headers['Content-Type'] = 'application/json';
        axios.defaults.withCredentials = true;
        try {
            const response = await axios.post('https://api.loan.ask-myna.com/ask-myna', {
                query: query,
                languageCode: 'en-US'
            });
            let rawResponse = response.data.response;

            try {
                rawResponse = JSON.parse(rawResponse);
            } catch (e) {
                console.error('Error parsing response:', e);
                setMessages((prev) => [...prev, { sender: 'agent', text: rawResponse }]);
                return;
            }
            const { text, values = [], creditScore = [], loans = [], rateOptionList = [], programNames = [] } = rawResponse;
            // Process loans to generate product cards
            if (loans.length > 0) {
                const productsCard = loans.map((loan) => ({
                    mynaId: loan.mynaId,
                    programId: loan.programId,
                    rateOptionId: loan.rateOptionId,
                    programName: loan.programName,
                    interestRate: loan.interestRate,
                    apr: loan.apr,
                    emi: loan.emi,
                    additionalRows: loan.rateOptionList.map(row => ({
                        ...row,
                        mynaId: loan.mynaId,  // Maintain mynaId for each row
                        programId: loan.programId,  // Maintain programId for each row
                    })),
                }));

                // Add productsCard and restProds in the same message if both exist
                setMessages((prev) => [
                    ...prev,
                    {
                        sender: 'agent',
                        text: text || 'loans available:',
                        productsCard: productsCard,
                        restProds: programNames.length > 0 ? programNames : []  // Add restProds only if programNames exist
                    }
                ]);
            }

            // Handle other possible options like creditScore or values
            if (values.length > 0) {
                setMessages((prev) => [
                    ...prev,
                    { sender: 'agent', text: text || 'Choose an option:', options: values }
                ]);
            }

            if (creditScore.length > 0) {
                setMessages((prev) => [
                    ...prev,
                    { sender: 'agent', text: text || 'Select credit score range:', options: creditScore }
                ]);
            }

        } catch (error) {
            console.error("Error fetching response:", error);
            setMessages((prev) => [...prev, { sender: 'agent', text: 'Error fetching response from server.' }]);
        } finally {
            setIsLoading(false);
        }
        setInput('');
    };

    const sendHiddenMessage = (message) => {
        handleSendMessage(message, true);
    };

    const handleCardOptionSelect = (product, row = null) => {

        if (!product) {
            console.warn("No product selected.");
            return;
        }
        // Construct hidden message for IDs
        const ids = row ? row : product;
        const { mynaId, programId, rateOptionId } = ids;

        if (mynaId && programId && rateOptionId) {
            sendHiddenMessage(`mynaId: ${mynaId}, programId: ${programId}, rateOptionId: ${rateOptionId}`);
        } else {
            console.warn("IDs are missing for hidden message.");
        }

        // Prepare values based on row or card selection
        const interestRate = row ? row.interestRate : product.interestRate;
        const apr = row ? row.apr : product.apr;
        const emi = row ? row.emi : product.emi;

        // Prepare visible message to be sent as an agent message
        const visibleMessage = row
            ? `Program: ${product.programName}, Rate: ${interestRate}, APR: ${apr}, Monthly Payment: ${emi}, Closing Cost: ${row.closingCost}, Price: ${row.price}`
            : `Program: ${product.programName}, Rate: ${interestRate}, APR: ${apr}, Monthly Payment: ${emi}`;

        // Ensure it's sent as an agent message
        handleSendMessage(visibleMessage, false, true); // Pass true for isAgentMessage
    };

    const handleOptionSelect = (optionValue) => {
        handleSendMessage(optionValue);
    };

    const toggleCardExpansion = (index) => {
        setExpandedCards((prevExpanded) => ({
            ...prevExpanded,
            [index]: !prevExpanded[index]
        }));
    };
    const toggleSpeechRecognition = () => {
        if (!recognition) return;

        if (isListening) {
            recognition.stop();
            setIsListening(false);
        } else {
            recognition.start();
            setIsListening(true);
        }

        recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            setInput(transcript);
            handleSendMessage(transcript, false, false);
        };

        recognition.onend = () => {
            setIsListening(false);
        };
    };


    return (
        <div className="chatbot-container">
        <div className="header" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '1px 20px',
            backgroundColor: '#7ca1e2', 
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
            borderBottom: '2px solid #d1e3f3', 
            height: '55px' 
        }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h1 style={{ fontSize: '1.0rem', fontWeight: '600', color: '#333', margin: 10, alignItems: 'center', height: '20px' }}>
                    LendingMan - AI Loan Officer
                </h1>
            </div>
        </div>
            <div className="chat-window">
                {messages.map((msg, index) => (
                    <ChatMessage
                        key={index}
                        message={msg}
                        userClass="user-message"
                        agentClass="agent-message"
                    />
                ))}
                {isLoading && (
                    <div className="loading-icon-container">
                        <CircularProgress /> {/* Centered loading spinner */}
                    </div>
                )}
                <div ref={messagesEndRef} />
                {messages.length > 0 && messages[messages.length - 1].options && (
                    <ChatOptions options={messages[messages.length - 1].options} onOptionSelect={handleOptionSelect} />
                )}

                {messages.length > 0 && messages[messages.length - 1].productsCard && (
                    <div className="card-container">
                        {messages[messages.length - 1].productsCard
                            .filter((product) => !selectedCourse || product.programName === selectedCourse)
                            .map((product, index) => (
                                <div className="product-card" key={index}>
                                    <h3>{product.programName}</h3>
                                    <p><strong>Rate:</strong> {product.interestRate}</p>
                                    <p><strong>APR:</strong> {product.apr}</p>
                                    <p><strong>Monthly Payment:</strong> {product.emi}</p>

                                    <div className="card-options">
                                        <button
                                            onClick={() => handleCardOptionSelect(product)}
                                            disabled={selectedCourse !== null}>
                                            Apply
                                        </button>
                                        <button
                                            onClick={() => toggleCardExpansion(index)}
                                            disabled={selectedCourse !== null}>
                                            {expandedCards[index] ? 'Less rates' : 'More rates'}
                                        </button>
                                    </div>

                                    {expandedCards[index] && (
                                        <div className="expanded-table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Rate</th>
                                                        <th>APR</th>
                                                        <th>Monthly Payment</th>
                                                        <th>Closing Cost</th>
                                                        <th>Price</th>
                                                        <th>Select loan</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {product.additionalRows && product.additionalRows.length > 0 ? (
                                                        product.additionalRows.map((row, rowIndex) => (
                                                            <tr key={rowIndex}>
                                                                <td>{row.interestRate || "N/A"}</td>
                                                                <td>{row.apr || "N/A"}</td>
                                                                <td>{row.emi || "N/A"}</td>
                                                                <td>{row.closingCost || "N/A"}</td>
                                                                <td>{row.price || "N/A"}</td>
                                                                <td>
                                                                    <button
                                                                        className="select-button"
                                                                        onClick={() => handleCardOptionSelect(product, row)}
                                                                        disabled={selectedCourse !== null}>
                                                                        Apply
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={6}>No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>
                )}

                {messages.length > 0 && Array.isArray(messages[messages.length - 1].restProds) && messages[messages.length - 1].restProds.length > 0 && (
                    <div className="rest-prods-container">
                        <div className="rest-prods-options">
                            <h4 className="select-to-view-more">Click to view details and apply:</h4>
                            {messages[messages.length - 1].restProds.map((option, index) => {
                                return (
                                    <button
                                        key={index}
                                        onClick={() => handleOptionSelect(option)}
                                        className="option-button"
                                    >
                                        {option}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                )}

            </div>
            <div className="chat-input-container">
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Type a message or use speech..."
                    className="chat-input"
                    onKeyPress={(e) => { if (e.key === 'Enter') handleSendMessage(input, false, false); }}
                />
                <button onClick={() => handleSendMessage(input, false, false)} className="send-button">Send</button>
                <button onClick={toggleSpeechRecognition} className={`speech-button ${isListening ? 'listening' : ''}`}>
                    🎤 {isListening ? 'Listening...' : ''}
                </button>
            </div>
            <div className="footer" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 10px', // Less padding for thinner footer
                backgroundColor: '#7ca1e2', // Match the header color
                borderTop: '1px solid #d1e3f3',
                height: '30px', // Make the footer thin
                position: 'relative', // Use 'relative' here so it doesn't overlap with page footer
                width: '98%',
                boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
            }}>
                <span style={{ fontSize: '0.9rem', color: '#333' }}>          This AI chat is powered by Kudiltech       </span>
            </div>

        </div>
    );
};

export default Chatbot;